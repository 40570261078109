


















































































































































import { Component, Vue } from "vue-property-decorator";
import {
  RedCapital,
  SelectedCountry,
  TitleTemplateDashboard
} from "@/configuration";
import {mapState} from 'vuex';
import API from "@/api";
import DatatableFilterPlugin from "@/filters";
import TableResponsive from "@/components/table/TableResponsive.vue";
// @ts-ignore
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

Vue.use(DatatableFilterPlugin);

@Component({
  metaInfo: {
    title: "Detalle pagos",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  computed: {
    ...mapState(["user", "setOpenMenuPanel"]),
    filteredDatatable() {
      return this.$data.transferences.filter((i: any) =>
        Object.keys(this.$data.filters).every(
          f =>
            this.$data.filters[f].length < 0 ||
            this.$data.filters[f].includes(i[f])
        )
      );
    },
    filtered2() {
      return this.$data.transferences.filter((i: any) => {
        // Props 
        let propsObjTableAll = {
          style: `text-align: center;
              font-size: 0.7rem;
              font-weight: 500;
              color: #8b8b8b`
        }
        // Props 
        let propsObjTableEstado = {
          style: `display: flex;
              justify-content: center;`
        }
        // Formato tabla
        let objTable= {
          id : {
							value: i.proyecto.id,
							label: 'ID',
							props: propsObjTableAll
					},
          nombre:{
            value: i.proyecto.nombre,
            label: 'Proyecto',
            props: propsObjTableAll
          },
          mes:{
            value: i.inversionista_flujo.mes,
            label: 'Mes',
            props: propsObjTableAll
          },
          fechaPago:{
            // @ts-ignore
            value: moment(i.created_at).format('DD-MM-YYYY'),
            label: 'Fecha pago',
            props: propsObjTableAll
          },
          montoPago:{
            value: i.monto,
            label: 'Monto pago',
            props: propsObjTableAll
          },
          vencimiento:{
            value: i.inversionista_flujo.vencimiento,
            label: 'Vencimiento cuota',
            props: propsObjTableAll
          },
          montoCuota:{
            value: i.inversionista_flujo.flujo_inv,
            label: 'Monto cuota',
            props: propsObjTableAll
          }

        }
        i.datosEnTabla = objTable;
        return (
          this.$data.name.includes(i.proyecto.nombre) ||
          this.$data.id.includes(i.proyecto.id)
        );
      });
    },
    setOpenMenuPanel(){
      return this.$store.state.openMenuPanel;
    }
  },
  data() {
    return {
      penpayment:'',
      filters: {
        id: null,
        name: null,
        state: null,
      },
      id: "",
      name: "",
      state: "",
      canShow: false,
      transferences: null,
      tableData:{
        headers: [
          {
            text: "ID",
            align: "center",
            value: "proyecto.id"
          },
          {
            text: "Proyecto",
            align: "center",
            value: "proyecto.nombre"
          },
          {
            text: "Mes",
            align: "center",
            value: "proyecto.id"
          },
          {
            text: "Fecha pago",
            align: "center",
            value: "created_at"
          },
          {
            text: "Monto pago",
            align: "center",
            value: "monto"
          },
          {
            text: "Vencimiento cuota",
            align: "center",
            value: "inversionista_flujo.vencimiento"
          },
          {
            text: "Monto cuota",
            align: "center",
            value: "inversionsita_flujo.flujo_inv"
          }
        ],
        msjEmpty: 'No existen registros en tu cuenta',
        rowsPerPage: {
          txt: 'Registro por página',
          itm: [10, 25, 50, { text: 'Todos', value: -1 }]
        },
        pagination: {
          sortBy: "created_at",
          descending: true,
          page: 1
        },

      },
      headers: [
        {
          text: "ID",
          align: "center",
          value: "proyecto.id"
        },
        {
          text: "Proyecto",
          align: "center",
          value: "proyecto.nombre"
        },
        {
          text: "Mes",
          align: "center",
          value: "proyecto.id"
        },
        {
          text: "Fecha pago",
          align: "center",
          value: "created_at"
        },
        {
          text: "Monto pago",
          align: "center",
          value: "monto"
        },
        {
          text: "Vencimiento cuota",
          align: "center",
          value: "inversionista_flujo.vencimiento"
        },
        {
          text: "Monto cuota",
          align: "center",
          value: "inversionsita_flujo.flujo_inv"
        }
      ]
    };
  },
  async beforeMount() {
    this.$data.transferences = await API.getTransferences();
    this.$data.penpayment = this.$data.transferences.map((i: any) => {
        return {
          Proyecto_id: i.proyecto.id,
          Proyecto: i.proyecto.nombre,
          mes: i.inversionista_flujo.mes,
          // @ts-ignore
          fecha_pago: moment(i.created_at).format('DD-MM-YYYY'),
          // @ts-ignore
          monto_pago: i.monto,
          vencimiento: i.inversionista_flujo.vencimiento,
          monto_cuota: i.inversionista_flujo.flujo_inv
        };
      })
    this.$data.transferences.forEach((el:any)=>{
      el.monto = parseFloat(el.monto)
    })
    this.$data.filters.id = this.$data.transferences
      .map((investment: any) => investment.proyecto.id)
      .filter((val: any) => val !== null);
    this.$data.filters.name = this.$data.transferences
      .map((investment: any) => investment.proyecto.nombre)
    this.$data.canShow = true;
  },
  methods: {
    resetFilters() {
      this.$data.id = "";
      this.$data.name = "";
      this.$data.state = ""
    }
  },
  components: {
    TableResponsive,
  }
})
export default class PaymentDetailss extends Vue {}
